<script setup lang="ts">
import { inject, onUnmounted, onMounted, ref, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
import Base from "@/components/UI/templates/DataCapturePageTemplate.vue";
import MainFooter from "@/components/UI/atoms/MainFooter.vue";

import IconElectric from "@/assets/icons/IconElectric.vue";
import IconGas from "@/assets/icons/IconGas.vue";
import IconBoth from "@/assets/icons/IconBoth.vue";

import { errorMessages } from "@/helpers/errorMessages";
import { useEnquiryStore } from "@/stores/enquiry";
import { useJourneyStore } from "@/stores/journey";
import { useJourneyConfig } from "@/composables/useJourneyConfig";
import {
  useAnalyticsClient,
  EVENT_CHECKBOX,
  EVENT_RADIO,
  PLATFORM_JOURNEY,
  JOURNEY_ENERGY,
  JOURNEY_ENERGY_DOMESTIC_REDIRECT,
} from "@/composables/useAnalyticsClient";
import {
  SERVICE_ELECTRICITY,
  SERVICE_GAS,
  INTENT_SOMETHING_ELSE,
  PAGE_NAME,
  PAGE_SERVICE,
  TRACKING_HANDOVER_SOMETHING_ELSE,
  TRACKING_DUAL_FUEL,
  PAGE_MULTI_PREMISE,
  PAGE_SERVICE_DOMESTIC,
  PAGE_ADDRESS,
  EXPERIMENT_SUPPLY_ADDRESS_PAGE,
  PAGE_SUPPLY_ADDRESS,
} from "@/helpers/constants";
import { useAssistantStore } from "@/stores/assistant";
import { useEvidentlyStore } from "@/stores/evidently";
import { AssistantMood } from "@/types/Assistants";
import { useEventProcessorService } from "@/composables/useEventProcessorService";
import {
  RadioCards,
  CheckBoxCards,
  useDelay,
  Constants,
} from "@makeitcheaper/rpp-frontend";
import AssistantToastMessage from "../molecules/AssistantToastMessage.vue";
import { AwsRumServiceKey } from "@/helpers/injectKeys";
import { PartnerCodes } from "@makeitcheaper/rpp-lib";
import { isSplitTestVariant } from "@/composables/useEvidentlySplitTest";

const enquiry = useEnquiryStore();
const journey = useJourneyStore();
const evidently = useEvidentlyStore();
const router = useRouter();
const route = useRoute();
const assistant = useAssistantStore();
const config = useJourneyConfig();
const analyticsClient = useAnalyticsClient();
const eventProcessorService = useEventProcessorService();
const awsRumService = inject(AwsRumServiceKey);

const { INFO } = Constants;

const heading = ref([] as string[]);
let localStateVal = "";
if (enquiry.productPayload.servicesRequired) {
  if (
    enquiry.productPayload.servicesRequired.includes(SERVICE_ELECTRICITY) &&
    enquiry.productPayload.servicesRequired.includes(SERVICE_GAS)
  ) {
    localStateVal = "dual-fuel";
  } else {
    localStateVal = enquiry.productPayload.servicesRequired[0];
  }
}
const localStateAlt = ref("");
const localState = ref(enquiry.productPayload.servicesRequired || []);
const errorMessage = ref("");
const pending = ref(false);
const displayContent = ref(false);
const displayFooter = ref(false);
const displayButtons = ref(false);
const displayToastMessage = ref(false);

const triggerGAJourneyStart = () => {
  route.name === PAGE_SERVICE
    ? analyticsClient.trackJourneyStarter(JOURNEY_ENERGY)
    : analyticsClient.trackJourneyStarter(JOURNEY_ENERGY_DOMESTIC_REDIRECT);

  analyticsClient.kpi({
    category: "QuoteStarted",
    label: "NewQuote",
  });
};

const triggerGATrackNext = () => {
  route.name === PAGE_SERVICE
    ? analyticsClient.trackNext(PAGE_SERVICE)
    : analyticsClient.trackNext(PAGE_SERVICE_DOMESTIC);
};

const triggerGASelectService = () => {
  route.name === PAGE_SERVICE
    ? analyticsClient.trackCustomInteraction(
        EVENT_CHECKBOX,
        PAGE_SERVICE,
        localState.value.length === 2
          ? TRACKING_DUAL_FUEL
          : localState.value.toString(),
        PLATFORM_JOURNEY,
      )
    : analyticsClient.trackCustomInteraction(
        EVENT_CHECKBOX,
        PAGE_SERVICE_DOMESTIC,
        localState.value.length === 2
          ? TRACKING_DUAL_FUEL
          : localState.value.toString(),
        PLATFORM_JOURNEY,
      );
};

const triggerGASelectServiceAlt = () => {
  route.name === PAGE_SERVICE
    ? analyticsClient.trackCustomInteraction(
        EVENT_RADIO,
        PAGE_SERVICE,
        localStateAlt.value == "dual-fuel"
          ? TRACKING_DUAL_FUEL
          : localState.value.toString(),
        PLATFORM_JOURNEY,
      )
    : analyticsClient.trackCustomInteraction(
        EVENT_RADIO,
        PAGE_SERVICE_DOMESTIC,
        localStateAlt.value == "dual-fuel"
          ? TRACKING_DUAL_FUEL
          : localState.value.toString(),
        PLATFORM_JOURNEY,
      );
};

const triggerGASomethingElse = () => {
  route.name === PAGE_SERVICE
    ? analyticsClient.trackNext(PAGE_SERVICE, TRACKING_HANDOVER_SOMETHING_ELSE)
    : analyticsClient.trackNext(
        PAGE_SERVICE_DOMESTIC,
        TRACKING_HANDOVER_SOMETHING_ELSE,
      );
};

const cardData = [
  {
    value: SERVICE_ELECTRICITY,
    body: {
      icon: IconElectric,
      heading: "Electricity",
      content: "A range of electricity suppliers for your power needs.",
    },
  },
  {
    value: SERVICE_GAS,
    body: {
      icon: IconGas,
      heading: "Gas",
      content: "Business gas options for heating and operational needs",
    },
  },
];

const cardDataAlt = [
  {
    value: SERVICE_ELECTRICITY,
    body: {
      icon: IconElectric,
      heading: "Electricity",
      content: "A range of electricity suppliers for your power needs.",
    },
  },
  {
    value: SERVICE_GAS,
    body: {
      icon: IconGas,
      heading: "Gas",
      content: "Business gas options for heating and operational needs",
    },
  },
  {
    value: "dual-fuel",
    body: {
      icon: IconBoth,
      heading: "Both",
      content: "Dual fuel options for your power and heating needs.",
    },
  },
];

const toastText = "Not here for a comparison? Let me know if you ";
const toastHyperlink = "need help with something else.";

const handleNextClickAlt = async () => {
  errorMessage.value = "";

  if (localStateAlt.value == "") {
    errorMessage.value = errorMessages.emptyOption;
    return;
  }
  pending.value = true;

  triggerGASelectServiceAlt();
  triggerGATrackNext();

  await eventProcessorService.updateEnquiry(enquiry.$state);

  router.push(
    isSplitTestVariant(evidently, EXPERIMENT_SUPPLY_ADDRESS_PAGE)
      ? PAGE_SUPPLY_ADDRESS
      : PAGE_ADDRESS,
  );
};

const handleNextClick = async () => {
  errorMessage.value = "";

  if (!localState.value.length) {
    errorMessage.value = errorMessages.emptyOption;
    return;
  }
  pending.value = true;
  handleChange(localState.value);
  triggerGASelectService();
  triggerGATrackNext();

  await eventProcessorService.updateEnquiry(enquiry.$state);
  router.push(PAGE_MULTI_PREMISE);
};

const handleBackClick = async () => {
  analyticsClient.trackBack((route.name as string) || PAGE_SERVICE);
};

function handleChangeAlt(selectedValue: string) {
  errorMessage.value = "";
  localStateAlt.value = selectedValue;

  if (localStateAlt.value == "dual-fuel") {
    enquiry.productPayload.servicesRequired = [
      SERVICE_ELECTRICITY,
      SERVICE_GAS,
    ];
  } else {
    enquiry.productPayload.servicesRequired = [localStateAlt.value];
  }
  enquiry.productPayload.enquiryIntent = undefined;
  enquiry.productPayload.call20 = true;
  handleNextClickAlt();
}

function handleChange(selectedValues: Array<string>) {
  errorMessage.value = "";
  localState.value = selectedValues;
  enquiry.productPayload.servicesRequired = localState.value;

  enquiry.productPayload.enquiryIntent = undefined;
  enquiry.productPayload.call20 = true;
}
function handleChangeAltAndNextClick(selectedValue: string) {
  handleChangeAlt(selectedValue);
  handleNextClickAlt();
}

async function handleSomethingElse() {
  triggerGASomethingElse();

  enquiry.productPayload.enquiryIntent = INTENT_SOMETHING_ELSE;
  enquiry.productPayload.servicesRequired = [];
  enquiry.productPayload.call20 = false;

  await eventProcessorService.updateEnquiry(enquiry.$state);

  router.push(PAGE_NAME);
}

watch(
  () => journey.appMountComplete,
  async () => {
    if (journey.appMountComplete) {
      await init();
    }
  },
);

onMounted(() => {
  if (journey.appMountComplete) {
    init();
  }
});

const init = async () => {
  if (!journey.altJourney) {
    awsRumService?.createRumEvent("JourneyStart", {
      enquiryId: eventProcessorService?.enquiryGuid,
      campaignGUID: eventProcessorService?.campaignGUID,
      partnerName: eventProcessorService?.partnerName,
      testLead: enquiry.trackingPayload.testLead,
    });

    triggerGAJourneyStart();

    if (route.name === PAGE_SERVICE_DOMESTIC) {
      journey.isDomesticRoutedCustomer = true;
    }

    assistant.setMood(AssistantMood.NEUTRAL);

    await useDelay(700);
    heading.value.push(
      config?.partner === PartnerCodes.CTM
        ? "Hi. I'm here to help!"
        : "Hi. I'm Bertie, here to help!",
    );

    await useDelay(700);
    heading.value.push("Which services are you looking to compare today?");
    assistant.setMood(AssistantMood.EXCITED);

    await useDelay(500);
    displayContent.value = true;

    await useDelay(400);
    displayButtons.value = true;

    await useDelay(300);
    displayToastMessage.value = true;

    await useDelay(200);
    displayFooter.value = true;
  } else {
    await useDelay(700);
    heading.value.push("Let’s get your energy sorted.");

    await useDelay(700);
    heading.value.push("What would you like to compare?");

    await useDelay(500);
    displayContent.value = true;

    await useDelay(400);
    displayButtons.value = true;

    await useDelay(200);
    displayFooter.value = true;
  }
};

onUnmounted(() => {
  assistant.setMood(AssistantMood.NEUTRAL);
});
</script>
<template>
  <div>
    <Base
      :heading="heading"
      :displayBack="false"
      :displayNext="journey.altJourney ? false : displayButtons"
      :next-is-disabled="!!errorMessage.length"
      :error-message="errorMessage"
      :is-loading="pending"
      :use-anims="true"
      @click="handleNextClick"
      @back="handleBackClick"
    >
      <RadioCards
        v-if="displayContent && journey.altJourney"
        :data="cardDataAlt"
        :local-state="localStateAlt"
        @change="handleChangeAltAndNextClick"
      />
      <CheckBoxCards
        v-if="displayContent && !journey.altJourney"
        :data="cardData"
        :local-state="localState"
        @change="handleChange"
      />
    </Base>
    <Transition name="slide-up">
      <AssistantToastMessage
        v-if="displayToastMessage"
        :partner="config?.partner"
        :toast-text="toastText"
        :toastHyperlink="toastHyperlink"
        @hyperlink="handleSomethingElse"
        :theme="INFO"
      />
    </Transition>
    <Transition name="slide-up">
      <MainFooter v-if="displayFooter" :alt-journey="journey.altJourney" />
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.Card__Content,
.Card__Heading {
  padding-right: 0;
}

.MainFooter {
  margin-top: var(--spacing--40);
  margin-bottom: var(--spacing--40);
}
</style>
