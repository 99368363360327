<script setup lang="ts">
const props = defineProps({
  altJourney: {
    type: Boolean,
    default: false,
  },
});

const heading = props.altJourney
  ? "By selecting one of the options above you confirm you agree to have read and understood our"
  : 'By pressing "Next" or "Help with something else", you confirm you agree to have read and understood our';
</script>

<template>
  <p class="MainFooter">
    You can find full details of how your data will be used by Bionic in our
    <a href="https://bionic.co.uk/about-bionic/privacy/"> Privacy Policy</a>.
    {{ heading }}
    <a href="https://bionic.co.uk/about-bionic/terms-and-conditions/">
      website Terms and Conditions</a
    >.
  </p>
</template>

<style lang="scss" scoped>
.MainFooter {
  @include fontSize(12px);
  color: var(--black--80);
  margin: var(--spacing--24) auto 0;
  max-width: toRem(640px);
  text-align: center;

  [partner="msm"] &,
  [partner="uswitch"] &,
  [partner="bbc"] & {
    font-family: var(--ff--secondary);
  }
  [partner="ctm"] & {
    font-family: var(--ff--secondary);
  }

  @media (min-width: $mq--sm) {
    margin-top: var(--spacing--52);
  }
}

a {
  color: var(--black--80);
  text-decoration: underline;
}
</style>
